export const environment = {
  production: true,
  envName: 'staging',
  urls: {
    moderationApi: 'https://moderation-api.staging.az.ebbo.com/'
  },
  oidc: {
    server: 'https://login.microsoftonline.com/050ea3de-6ab5-4bb6-ae57-0100b14df04d/v2.0',
    redirectUrl: 'https://moderation-web.staging.az.ebbo.com',
    clientId: 'e4c44a62-4c8f-4d47-94f0-1f86a7cf2527',
    scopes: 'openid profile',
    logoutRedirectUrl: 'https://moderation-web.staging.az.ebbo.com/',
    issuerUri: 'https://login.microsoftonline.com/050ea3de-6ab5-4bb6-ae57-0100b14df04d/v2.0'
  },
  retryAttempts: 2
};
